<template>
  <div class="log">
    <v-btn
      class="reset-btn-color"
      flat
      @click.stop="dialog = true"
      prepend-icon
      @click="getCharges"
      color="orange darken-1"
      >Driver Charges</v-btn
    >

    <v-dialog v-model="dialog" max-width="50%" persistent>
      <v-form ref="form" onSubmit="return false;">
        <v-card class="comments-view">
          <v-toolbar fixed flat>
            <v-toolbar-title>Driver Charges</v-toolbar-title>
            <v-spacer></v-spacer>
            <v-btn icon @click.prevent="dialog = false">
              <v-icon class="heading grey--text text--darken-4">close</v-icon>
            </v-btn>
          </v-toolbar>
          <v-progress-linear
            indeterminate
            color="trukkin-theme darken-2"
            v-show="loading"
          ></v-progress-linear>

          <v-spacer class="address-pop">
            <v-layout>
              <v-flex xs3 md4>
                <span class="muted positionCharge">Cancellation Charges</span>
              </v-flex>

              <v-flex xs2 md2 ml-4>
                <v-text-field
                  class=""
                  slot="activator"
                  onkeypress="return event.charCode >= 48"
                  suffix="SAR"
                  type="number"
                  min="1"
                  v-model="cancellationChargesInSar"
                ></v-text-field>
              </v-flex>

              <!-- <v-flex xs3 md3 >
                  <span class="muted positionCharge">Cancellation Currency</span>
                </v-flex>  -->

              <v-flex xs2 md2 ml-3>
                <v-text-field
                  class=""
                  onkeypress="return event.charCode >= 48"
                  slot="activator"
                  suffix="AED"
                  type="number"
                  min="1"
                  v-model="cancellationChargesInAed"
                ></v-text-field>
              </v-flex>
            </v-layout>
            <v-layout>
              <v-flex xs3 md4>
                <span class="muted positionWaiting">Waiting Charges</span>
              </v-flex>

              <v-flex xs2 md2 ml-4>
                <v-text-field
                  slot="activator"
                  onkeypress="return event.charCode >= 48"
                  suffix="SAR"
                  type="number"
                  min="1"
                  v-model="waitingChargesInSar"
                ></v-text-field>
              </v-flex>

              <!-- <v-flex xs3 md3>
                  <span class="muted positionWaiting">Waiting Charges</span>
                </v-flex>
                 -->
              <v-flex xs2 md2 ml-3>
                <v-text-field
                  slot="activator"
                  suffix="AED"
                  onkeypress="return event.charCode >= 48"
                  type="number"
                  min="1"
                  v-model="waitingChargesInAed"
                ></v-text-field>
              </v-flex>
            </v-layout>

            <v-layout>
              <div
                v-if="chargesSaved"
                style="
                  color: green;
                  border: 0px;
                  margin: auto;
                  text-align: center;
                "
              >
                {{ this.success }}
              </div>

              <div
                v-if="haserror"
                style="
                  color: red;
                  border: 0px;
                  margin: auto;
                  text-align: center;
                "
              >
                {{ this.x.error }}
              </div>
            </v-layout>

            <v-layout>
              <v-flex xs8 md8 ml-4 pb-4> </v-flex>
              <v-flex xs2 md2 ml-4 pb-4>
                <button
                  type="button"
                  class="track-ass-btn green-button fontsizecharge"
                  @click="addCharges()"
                >
                  Save
                </button>
              </v-flex>
              <v-flex xs2 md2>
                <button
                  class="track-ass-btn Cancel"
                  @click.prevent="dialog = false"
                >
                  Cancel
                </button>
              </v-flex>
            </v-layout>
          </v-spacer>
        </v-card>
      </v-form>
    </v-dialog>
  </div>
</template>
<script>
import { StorageKeys } from "../../constants/constants";
import { ChargesPopup } from "../../constants/api-urls.js";

export default {
  computed: {
    haserror() {
      return this.x.error !== null;
    },
    hassucess() {
      setTimeout(() => {
        this.chargesSaved = "";
      }, 1000);
      this.loading = false;
      return false;
    },
  },

  data() {
    return {
      chargesSaved: "",

      currency: ["SAR", "AED"],
      cancellationChargesInAed: "",
      cancellationChargesInSar: "",
      waitingChargesInAed: "",
      waitingChargesInSar: "",
      x: {
        error: null,
      },

      processing: false,
      reason: "",
      dialog: false,
      disStatus: false,
      loading: false,
      rules: {
        required: (value) => !!value || "This field is required.",
      },
    };
  },
  props: {
    charges: Object,
  },
  created() {
    // this.getActivityLog();
  },

  methods: {
    getCharges() {
      if (!navigator.onLine) {
        this.loading = false;
        this.x.error = "Please check your internet connection";

        return;
      }

      this.loading = true;
      let url = ChargesPopup.getCharges;
      delete this.axios.defaults.headers.common["token"];
      let token = localStorage.getItem(StorageKeys.SessionKey);
      let config = {
        headers: {
          authorization: `bearer ${token}`,
        },
      };
      let body = {};
      this.axios.post(this.constants.apiUrl + url, body, config).then(
        (response) => {
          this.loading = false;

          this.cancellationChargesInSar =
            response.data.data[0].cancellationChargesInSar;
          this.cancellationChargesInAed =
            response.data.data[0].cancellationChargesInAed;
          this.waitingChargesInSar = response.data.data[0].waitingChargesInSar;
          this.waitingChargesInAed = response.data.data[0].waitingChargesInSar;
        },
        (error) => {
          this.loading = false;
          this.x.error = "Failed to Fetch User Details";
        }
      );
    },

    addCharges() {
      const mandatory = [
        "cancellationChargesInAed",
        "cancellationChargesInSar",
        "waitingChargesInAed",
        "waitingChargesInSar",
      ];
      if (!this.cancellationChargesInSar) {
        this.x.error =
          "Please provide Cancellation Charges before moving ahead!";
        this.loading = false;
        return;
      }
      if (!this.cancellationChargesInAed) {
        this.x.error =
          "Please provide Cancellation Charges before moving ahead!";
        this.loading = false;
        return;
      }
      if (
        this.cancellationChargesInAed <= 0 ||
        this.cancellationChargesInSar <= 0 ||
        this.waitingChargesInAed <= 0 ||
        this.waitingChargesInSar <= 0
      ) {
        this.x.error = "Please provide the valid charges";
        this.loading = false;
        return;
      }

      if (!this.waitingChargesInSar) {
        this.x.error = "Please provide Waiting Charges before moving ahead!";
        this.loading = false;
        return;
      }
      if (!this.waitingChargesInAed) {
        this.x.error = "Please provide Waiting Charges before moving ahead!";
        this.loading = false;
        return;
      } else {
        this.loading = true;
        let url = ChargesPopup.addCharges;
        delete this.axios.defaults.headers.common["token"];
        let token = localStorage.getItem(StorageKeys.SessionKey);
        let config = {
          headers: {
            authorization: `bearer ${token}`,
          },
        };
        let body = {
          cancellationChargesInSar: this.cancellationChargesInSar,
          cancellationChargesInAed: this.cancellationChargesInAed,
          waitingChargesInSar: this.waitingChargesInSar,
          waitingChargesInAed: this.waitingChargesInAed,
        };

        this.axios.post(this.constants.apiUrl + url, body, config).then(
          (response) => {
            this.x.error = null;
            this.items = response.data.data;

            this.chargesSaved = true;
            setTimeout(() => {
              this.chargesSaved = "";
            }, 1000);
            this.success = "Saved!";

            this.loading = false;
          },
          (error) => {
            this.loading = false;

            this.x.error = "Failed to update inquiry status";
          }
        );
      }
    },
  },
};
</script>
<style scoped>
.break_long_text {
  word-break: break-all;
  word-break: break-word;
  hyphens: auto;
}
.user {
  padding: 10px;
}
.log {
  margin-left: 10px;
}
.comments-view .v-toolbar {
  width: 100%;
  /* margin-left: 25%; */
}
.v-toolbar--fixed {
  position: inherit;
}
.view-ass-btn {
  text-decoration: none;
  color: #ffffff;
  background-color: #c87ffe;
  border: 2px solid indigo;
  padding: 2px 5px;
  border-radius: 5px;
}
.positionCharge {
  position: fixed;
  padding-top: 20px;
  font-size: large;
}
.green-button {
  border-radius: 5px;
  background-color: #63ae63 !important;
  color: #ffffff !important;
  border: 2px solid green !important;
}

.positionWaiting {
  position: absolute;
  top: 205px;
  font-size: large;
}
.fontsizecharge {
  font-size: large;
  position: absolute;
  /* left: 550px; */
}
.Cancel {
  position: absolute;
  /* left: 650px; */
  font-size: large;
}
.alert {
  position: absolute;
  left: 300px;
  font-size: large;
  color: green;
}
</style>
